<template>
    <div class="publishMenu dropdown-menu dropdown-menu-dark show"
         :style="{left: popUpLeft + 'px', top: popUpTop + 'px' }">
        <div class="margin">
            <div class="top">
                <button type="button" class="btn-close" @click="btnCloseClick()">
                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="-1.5" x2="24.5615" y2="-1.5" transform="matrix(0.727022 0.686615 -0.727022 0.686615 1 3)" stroke="#AAABAB" stroke-width="3"/>
                    <line y1="-1.5" x2="24.5615" y2="-1.5" transform="matrix(-0.727022 0.686615 0.727022 0.686615 20.8564 3)" stroke="#AAABAB" stroke-width="3"/>
                    </svg>
                </button>
                <p class="text-center m-0">Publish Drive</p>
            </div>
            <ul class="p-0 m-0">
                <li v-for="item in driveList.filter(object => object.FILE_TYPE === 'FOLDER')" v-bind:key="item">
                    <button class="dropdown-item" @click="selectTargetDrive(item)">
                        <folder-icon :width="30" :height="17"/>
                        {{item.FILE_NAME}}
                    </button>
                </li>
                <!-- <li class="dropdown-divider"></li>
                <li>
                    <span class="target-folder">
                        <folder-icon :width="20" :height="17"/>
                        {{selectedFolder.FILE_NAME}}
                    </span>
                    <button class="dropdown-item text-end" @click="btnPublishClick()">{{ $t('여기에 공유하기') }}</button>
                </li> -->
            </ul>
            <ul class="publishBtn">
                <li>
                    <span class="target-folder">
                        <folder-icon :width="30" :height="17"/>
                        {{selectedFolder.FILE_NAME}}
                    </span>
                    <button class="dropdown-item text-end" @click="btnPublishClick()">{{ $t('여기에 공유하기') }}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {defineAsyncComponent} from 'vue'
const FolderIcon = defineAsyncComponent(() => import ("@/components/atoms/FolderIcon"));

import {$axios, $session} from "dot-framework";

export default {
    name: "PublishMenu",
    components: {
        FolderIcon
    },
    props: {
        data: Object,
        popUpTop: Number,
        popUpLeft: Number
    },
    data() {
        return {
            driveList:[], // Publish Drive 목록
            selectedFolder: {}, // 선택된 폴더
        }
    },
    mounted() {
        this.setPublishDriveList();
        document.addEventListener('keydown', this.handleKeyDown);
    },
    unmounted() {
        // ⚠️ 컴포넌트 제거 됐을 때 동작
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        handleKeyDown(event) {
            if (event.key === "Escape") {
                this.btnCloseClick();
            }
        },
        // Publish Drive 목록 조회
        async setPublishDriveList(){
            const params = {
                COMP_NO: $session.getCompNo() || this.$store.getters.COMP_NO,
                PARENT_GROUP_NO: 'ROOT',
                DRIVER_KIND: 'D',
                USER_NO: ''
            };
            // 값이 없는 속성 제거
            Object.keys(params)
                .map(key => {
                    if (!params[key]) {
                        delete params[key];
                    }
                });
            const query = Object.keys(params).length !== 0 ? `?${new URLSearchParams(params).toString()}` : '';
            const url = `/vision-app/v1/dtms/groups${query}`;
            const response = await $axios.get(url);
            if (response.status === 200) {
                this.driveList = response.data.items;
            }
        },
        // 공개할 폴더 선택
        selectTargetDrive(folder){
            this.selectedFolder = folder;
        },
        // 이곳에 공개하기 버튼 클릭
        async btnPublishClick(){
            if(!this.selectedFolder.FILE_KEY){
                alert(this.$t("공개할 폴더를 선택해주세요."));
                return;
            }

            let url = `/vision-app/v1/dtms/images/${this.data.FILE_KEY}/open`;
            let data = {
                "TARGET_GROUP_NO": this.selectedFolder.FILE_KEY,
                "USER_NO": $session.getUserNo()
            }

            let response = await $axios.post(url, data, true);
            if (response.status === 201) {
                await this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});

                // 팝업 창 끄기
                this.btnCloseClick();
            }
        },
        // 팝업 창 끄기
        btnCloseClick(){
            this.$parent.showsPublishDialog = false;
        }
    }
}
</script>

<style scoped>
.publishMenu {
    position: absolute;
    width: 280px;
    z-index: 1;
    background-color: #AAABAB;
    color: white;
    border-radius: 30px;
    padding: 0 !important;
}
.publishMenu .margin {
    margin: 10px;
}
.publishMenu .top:after {
    content: "";
    clear: both;
    display: block;
}
.publishMenu .top > .btn-close {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f7f7f7;
    box-sizing: border-box;
}
.publishMenu .top > p {
    font-size: 20px;
    line-height: 40px;
}

.publishMenu ul {
    list-style: none;
    font-size: 18px;
    background: #fff;
    border-radius: 15px;
    margin-top: 20px !important;
    max-height: 250px;
    overflow-y: auto;
}
.publishMenu ul > li {
    height: 48px;
    width: 100%;
}
.publishMenu ul > li > button {
    text-decoration-line:none;
    height: 48px;
    color: #44403F;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}
.publishMenu .publishBtn {
    position: relative;
    margin-top: 10px !important;
    padding: 0 !important;
}

.publishMenu .publishBtn .target-folder {
    position: absolute;
    bottom: 12px;
    left: 17px;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    color: #44403F;
    font-weight: 500;
}
.publishMenu .publishBtn button {
    margin: 0 !important;
    max-width: 100%;
}
</style>
