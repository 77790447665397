<template>
    <div class="modal position-absolute d-block" tabindex="-1" style="background-color: rgba(0, 0, 0, 0.5);">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content rounded-4 shadow">
                <form v-on:submit.prevent="rename">
                    <div class="modal-header p-3 pb-2 border-bottom-0"></div>
                    <h2 class="fw-bold d-flex">{{ $t('이름 바꾸기') }}</h2>
                    <div class="modal-body p-5 pt-0">
                        <div class="mb-3">
                            <label class="form-label" for="newName">{{ $t('새 폴더 이름') }}</label>
                            <input type="text" class="form-control" id="newName" v-model="newName" :placeholder="$t('새 이름을 입력하세요')">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ $t('취소') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('저장') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import {$axios, $session} from "dot-framework";
import global from "@/js/global.js";

export default {
    props: {
        data: Object
    },
    data() {
        return {
            newName: ""
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
        this.newName = this.data.FILE_NAME;
    },
    unmounted() {
        // ⚠️ 컴포넌트 제거 됐을 때 동작
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        handleKeyDown(event) {
            if (event.key === "Escape") {
                this.$emit('close');
            }
        },
        async rename() {
            let url = "";
            let params = {};
            let response;

            if (this.data.FILE_TYPE === "FOLDER") {
                url = `/vision-app/v1/dtm/groups/${this.data.FILE_KEY}`;
                params = {
                    DTM_GROUP_NO: this.data.FILE_KEY,
                    DTM_GROUP_NAME: this.newName,
                    USER_NO: $session.getUserNo() === "GUEST" ? null : $session.getUserNo()
                };
            } else if (this.data.FILE_TYPE === "FILE") {
                url = `/vision-app/v1/dtms/images/${this.data.FILE_KEY}`;
                params = {
                    DTM_NO: this.data.FILE_KEY,
                    DTM_NAME: this.newName,
                    USER_NO: $session.getUserNo() === "GUEST" ? null : $session.getUserNo()
                };
            }

            response = await $axios.put(url, params, true);

            if (response.status === 200) {
                await this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
                this.$emit('close');
            } else {
                global.setAxiosError(response);
            }
        }
    }
}
</script>

<style scoped>
.modal-content {
    border-radius: 40px !important;
    background: #F7F7F7;
}

.modal-content form {
    text-align: center;
}

.modal-content form label {
    font-size: 20px;
    color: #ea5414;
}

.modal-content form input {
    height: 40px;
    font-size: 18px;
}

.modal-content form input::placeholder {
    color: #AAABAB;
}

.modal-content h2 {
    font-size: 28px;
    line-height: 28px;
    justify-content: center;
}

.modal-body {
    padding: 0 30px !important;
}

.mb-3 {
    margin-bottom: 10px !important;
}

.modal-footer {
    border-top: none;
    justify-content: flex-end;
    padding-bottom: 30px;
}

.modal-footer button {
    font-size: 24px;
    width: 120px;
    height: 48px;
    border-radius: 30px;
    margin: 0 10px;
}

.btn-primary {
    background: #EA5414;
    border-color: #EA5414;
}

.btn-primary:active {
    background: #EB7340;
    border-color: #EB7340;
}

.btn-secondary {
    background: #AAABAB;
    border-color: #AAABAB;
}

.btn-secondary:active {
    background: #c3c4c4;
    border-color: #c3c4c4;
}
</style>
