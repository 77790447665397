<template>
    <ul class="dropdown-menu p-0 rounded-5 context-menu"
        :style="{ display: popupDisplay, left: popUpLeft + 'px', top: popUpTop + 'px' }">
        <li v-if="showsNewFileButton"><router-link to="canvas" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledNewFileButton}" @click="hidePopup();">{{ $t('새 파일 만들기') }}</router-link></li>
        <li v-if="showsNewFolderButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledNewFolderButton}" @click="createNewFolder(); hidePopup();">{{ $t('새 폴더 만들기') }}</a></li>
        <li v-if="showsUploadButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledUploadButton}" @click="btnUploadClick(); hidePopup();">{{ $t('파일 업로드') }}</a></li>
        <li v-if="showsOpenButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledOpenButton}" @click="btnOpenClick(); hidePopup();">{{ $t('열기') }}</a></li>
        <li v-if="showsPublishButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledPublishButton}" @click="showsPublishDialog = true; hidePopup();">{{ $t('공유') }}</a></li>
        <li v-if="showsUnPublishButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledPublishButton}" @click="btnUnPublishClick(); hidePopup();">{{ $t('공유 취소') }}</a></li>
        <li v-if="showsDownloadButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledDownloadButton }" @click="btnDownloadClick(); hidePopup();">{{ $t('다운로드') }}</a></li>
        <li v-if="showsRenameButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledRenameButton}" @click="showsRenameDialog = true; hidePopup();">{{ $t('이름 바꾸기') }}</a></li>
        <li v-if="showsCopyButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledCopyButton}" @click="btnCopyClick(); hidePopup();">{{ $t('복사') }}</a></li>
        <li v-if="showsCutButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledCutButton}" @click="btnCutClick(); hidePopup();">{{ $t('잘라내기') }}</a></li>
        <li v-if="showsPasteButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledPasteButton}" @click="btnPasteClick(); hidePopup();">{{ $t('붙여넣기') }}</a></li>
        <li v-if="showsDeleteButton"><a href="#" class="dropdown-item text-white text-center" role="button" :class="{disabled: isDisabledDeleteButton}" @click="btnDeleteClick(); hidePopup();">{{ $t('삭제') }}</a></li>
    </ul>

    <rename-dialog v-if="showsRenameDialog" @close="showsRenameDialog = false" :data="selectedItem"/>
    <publish-dialog v-if="showsPublishDialog" @close="showsPublishDialog = false" :data="selectedItem" :popUpTop="popUpTop" :popUpLeft="popUpLeft"/>

    <form enctype="multipart/form-data">
      <input type="file" ref="uploadDtms" accept=".dtms" @change="uploadDtmsFile" multiple hidden/>
    </form>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {$axios, $session} from "dot-framework";
import global from "@/js/global.js";
import PublishDialog from '@/views/drive/PublishDialog.vue';
import RenameDialog from "@/views/drive/RenameDialog.vue";

export default {
    name: ".context-menu",
    components: {
        PublishDialog,
        RenameDialog
    },
    props: {
        currentPath: Object,
        selectedItem: Object,
    },
    inject: ['openCreateModal'],
    data() {
        return {
            popUpTop: 0,
            popUpLeft: 0,
            popupDisplay: 'none',
            isHidden: true,

            showsNewFileButton: false,
            showsNewFolderButton: false,
            showsUploadButton: false,

            showsOpenButton: false,
            showsPublishButton: false,
            showsUnPublishButton: false,
            showsDownloadButton: false,
            showsRenameButton: false,
            showsCopyButton: false,
            showsCutButton: false,
            showsPasteButton: false,
            showsDeleteButton: false,

            showsPublishDialog: false, // 공개 dialog
            showsRenameDialog: false, // 이름 바꾸기 dialog

            isDisabledNewFileButton: false,
            isDisabledNewFolderButton: false,
            isDisabledUploadButton: false,

            isDisabledOpenButton: false,
            isDisabledPublishButton: false,
            isDisabledDownloadButton: false,
            isDisabledRenameButton: false,
            isDisabledCopyButton: false,
            isDisabledCutButton: false,
            isDisabledPasteButton: true,
            isDisabledDeleteButton: false,

            selectedFolder: {}, // 선택된 폴더
            checkedGridRows: [], // 그리드에서 체크된 행들
        }
    },
    computed: {
        ...mapGetters("drive", [
            "clipboard",
            "clipboardMode",
            "driverKind",
            "isPrivate",
            "isPublic",
        ]),
    },
    beforeUpdate() {
        // show & hide
        if (this.isPrivate) {
            if (this.isFolder()) {
                this.showsNewFileButton = false;
                this.showsNewFolderButton = false;
                this.showsUploadButton = false;

                this.showsOpenButton = true;
                this.showsPublishButton = true;
                this.showsDownloadButton = true;
                this.showsRenameButton = true;
                this.showsCopyButton = true;
                this.showsCutButton = true;
                this.showsPasteButton = true;
                this.showsDeleteButton = true;
            } else if (this.isFile()) {
                this.showsNewFileButton = false;
                this.showsNewFolderButton = false;
                this.showsUploadButton = false;

                this.showsOpenButton = true;
                this.showsPublishButton = true;
                this.showsDownloadButton = true;
                this.showsRenameButton = true;
                this.showsCopyButton = true;
                this.showsCutButton = true;
                this.showsPasteButton = true;
                this.showsDeleteButton = true;
            }
            // 빈화면을 우클릭 할 경우
            else {
                this.showsNewFileButton = true;
                this.showsNewFolderButton = true;
                this.showsUploadButton = true;

                this.showsOpenButton = false;
                this.showsPublishButton = false;
                this.showsDownloadButton = false;
                this.showsRenameButton = false;
                this.showsCopyButton = false;
                this.showsCutButton = false;
                this.showsPasteButton = true;
                this.showsDeleteButton = false;
            }
        } else if (this.isPublic) {
            if (this.isFolder()) {
                const isAdmin = global.isDotAdmin();

                if (!isAdmin) {
                    this.hidePopup();
                }

                this.showsNewFileButton = false;
                this.showsNewFolderButton = isAdmin ? this.currentPath.DTM_GROUP_LEVEL < 2 : false;
                this.showsUploadButton = false;

                this.showsOpenButton = false;
                this.showsPublishButton = false;
                this.showsDownloadButton = false;
                this.showsRenameButton = isAdmin;
                this.showsCopyButton = false;
                this.showsCutButton = false;
                this.showsPasteButton = false;
                this.showsDeleteButton = isAdmin;
            } else if (this.isFile()) {
                this.showsNewFileButton = false;
                this.showsNewFolderButton = false;
                this.showsUploadButton = false;

                this.showsOpenButton = true;
                this.showsPublishButton = false;
                this.showsDownloadButton = true;
                this.showsRenameButton = false;
                this.showsCopyButton = false;
                this.showsCutButton = false;
                this.showsPasteButton = false;
                this.showsDeleteButton = false;
            }
            // 빈화면을 우클릭 할 경우
            else {
                const isAdmin = global.isDotAdmin();

                if (!isAdmin) {
                    this.hidePopup();
                }

                this.showsNewFileButton = false;
                this.showsNewFolderButton = isAdmin ? this.currentPath.DTM_GROUP_LEVEL < 2 : false;
                this.showsUploadButton = false;

                this.showsOpenButton = false;
                this.showsPublishButton = false;
                this.showsDownloadButton = false;
                this.showsRenameButton = false;
                this.showsCopyButton = false;
                this.showsCutButton = false;
                this.showsPasteButton = isAdmin;
                this.showsDeleteButton = false;
            }
        }

        // disable
        if (this.isFolder()) {
            this.isDisabledPublishButton = true;
            this.isDisabledDownloadButton = true;
            this.isDisabledPasteButton = this.clipboard.length > 0 ? this.isPublic : true;

            if (this.isPublic) {
                if (this.selectedItem?.FILE_COUNT) {
                    const fileCount = parseInt(this.selectedItem.FILE_COUNT);
                    this.isDisabledDeleteButton = isNaN(fileCount) || fileCount > 0;
                } else {
                    this.isDisabledDeleteButton = true;
                }
            }
        } else if (this.isFile()) {
            this.isDisabledPublishButton = false;
            this.isDisabledDownloadButton = false;
            this.isDisabledPasteButton = this.clipboard.length > 0 ? this.isPublic : true;
        }
        // 빈화면을 우클릭 할 경우
        else {
            if (global.isDotAdmin()) {
                this.isDisabledNewFileButton = this.isPublic;
                this.isDisabledNewFolderButton = false;
                this.isDisabledUploadButton = this.isPublic;
                this.isDisabledPasteButton = this.clipboard.length <= 0;
            } else {
                this.isDisabledNewFileButton = this.isPublic;
                this.isDisabledNewFolderButton = this.isPublic;
                this.isDisabledUploadButton = this.isPublic;
                this.isDisabledPasteButton = this.clipboard.length > 0 ? this.isPublic : true;
            }
        }

        if (this.isPublish()) {
            this.showsPublishButton = false;
            this.showsUnPublishButton = true;
        } else {
            this.showsUnPublishButton = false;
        }
    },
    emits: ["onEnter"],
    methods: {
        ...mapMutations("drive", [
            "clearClipboard",
            "pushClipboard",
            "setClipboardMode",
        ]),
        onRowClicked(event) {
            this.setPopupPosition(event.pageX, event.pageY);
            this.showPopup();
        },
        setPopupPosition(X, Y) {
            this.popUpLeft = X;
            this.popUpTop = Y;
        },
        showPopup() {
            this.popupDisplay = 'block';
            this.isHidden = false;
            this.showsPublishDialog = false;
        },
        hidePopup() {
            this.popupDisplay = 'none';
            this.isHidden = true;
        },
        createNewFolder() {
            this.openCreateModal();
        },
        btnUploadClick() {
            this.$refs.uploadDtms.click();
        },
        async uploadDtmsFile(event) {
            const allowedFileExtensions = /(dtms)$/i; // /(dtms|dtmx|zip|brf|brl)$/i;
            const dtmsFiles = event.target.files;
            const formData = new FormData();
            formData.append('DTM_GROUP_NO', this.$route.query.parentGroupNo || 'ROOT');
            formData.append('DEVICE_KIND', '300');
            formData.append('USER_NO', $session.getUserNo());

            for (const dtmsFile of dtmsFiles) {
                const filename = dtmsFile.name;
                const extension = filename.lastIndexOf('.') !== -1 ? filename.substring(filename.lastIndexOf('.') + 1) : '';

                if (!allowedFileExtensions.test(extension)) {
                    return;
                }

                formData.append('DTM_FILES', dtmsFile);
            }

            const url = "/vision-app/v1/dtms/images";
            await $axios.post(url, formData, true)
                .then(response => {
                    if (response.status === 201) {
                        // 화면 리프레시
                        this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
                    }
                })
                .finally(() => {
                    event.target.value = "";
                });
        },
        btnOpenClick() {
            this.$emit('onEnter', this.selectedItem);
        },
        // 다운로드 버튼 클릭
        btnDownloadClick() {
            if (this.selectedItem.FILE_TYPE === "FILE") {
                window.open(`/vision-app/file/${this.selectedItem.DTM_IMG}`);
            }
        },
        // 비공개 버튼 클릭
        async btnUnPublishClick() {
            const url = `/vision-app/v1/dtms/images/${this.selectedItem.FILE_KEY}/open`;

            const response = await $axios.delete(url, null, true);
            if (response.status === 200) {
                await this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
            }
        },
        // 현재 폴더에 파일, 폴더 복사하기
        async setCopyFile(_copyData) {
            let url = "";
            let params = {};

            if (Array.isArray(_copyData)) {
                for (const obj of _copyData) {
                    params = {
                        "TARGET_GROUP_NO": this.$route.query.parentGroupNo || "",
                        "USER_NO": $session.getUserNo()
                    };

                    // 폴더 복사
                    if (obj.FILE_TYPE === "FOLDER") {
                        url = `/vision-app/v1/dtms/groups/${obj.FILE_KEY}/copy`;
                        Object.assign(params, {
                            "DTM_GROUP_NO": obj.FILE_KEY,
                        });
                    }
                    // 파일 복사
                    else if (obj.FILE_TYPE === "FILE") {
                        url = `/vision-app/v1/dtms/images/${obj.FILE_KEY}/copy`;
                        Object.assign(params, {
                            "DTM_NO": obj.FILE_KEY,
                        });
                    }

                    await $axios.post(url, params, true);
                }
            }
        },
        // 복사버튼 클릭
        btnCopyClick() {
            this.setClipboardMode("COPY");
            this.clearClipboard();
            this.pushClipboard(this.selectedItem);
        },
        // 붙여넣기버튼 클릭
        async btnPasteClick() {
            await this.setCopyFile(this.clipboard);

            switch (this.clipboardMode.description) {
                case "CUT":
                    await this.deleteClipboardData();
                    break;
            }

            await this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
        },
        async deleteClipboardData() {
            let url = '';

            for (let i = 0, clipboardLength = this.clipboard.length; i < clipboardLength; i++) {
                const clipboardItem = this.clipboard[i];
                // 폴더 삭제
                if (clipboardItem.FILE_TYPE === "FOLDER") {
                    url = `/vision-app/v1/dtm/groups/${clipboardItem.FILE_KEY}`;
                }
                // 파일 삭제
                else if (clipboardItem.FILE_TYPE === "FILE") {
                    url = `/vision-app/v1/dtm/images/${clipboardItem.FILE_KEY}`;
                }
                await $axios.delete(url, null, true);
            }

            this.clearClipboard();
        },
        // 삭제버튼
        async btnDeleteClick() {
            const message = this.$t("삭제하시겠습니까?");

            this.$swal({
                title: this.$t("삭제"),
                text: message,
                showCancelButton: true,
                confirmButtonText: this.$t("삭제"),
                cancelButtonText: this.$t("취소"),
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    const isFolder = this.selectedItem.FILE_TYPE === "FOLDER";
                    const resourceType = isFolder ? 'groups' : 'images';
                    const url = `/vision-app/v1/dtm/${resourceType}/${this.selectedItem.FILE_KEY}`;
                    return $axios.delete(url, null, true);
                }
            }).then(() => {
                this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo})
            });
        },
        // 잘라내기 버튼
        async btnCutClick() {
            this.setClipboardMode("CUT");
            this.clearClipboard();
            this.pushClipboard(this.selectedItem);
        },
        isFolder() {
            return this.selectedItem?.FILE_TYPE === "FOLDER";
        },
        isFile() {
            return this.selectedItem?.FILE_TYPE === "FILE";
        },
        isPublish() {
            return this.selectedItem?.PUBLISH_COUNT > 0;
        }
    }
}
</script>

<style scoped>
.dropdown-menu {
    background-color: #AAABAB;
}

.dropdown-menu > li:not(:last-child) {
    border-bottom: 1.5px solid white;
}

.context-menu {
    z-index: 1021;
}

.context-menu > li > a:hover {

}

.context-menu > li:first-child > a:hover {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}

.context-menu > li:last-child > a:hover {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.context-menu > li > a.disabled {
    color: lightgray !important;
    pointer-events: none;
    background: transparent;
}
</style>
